import { Relationship, RelationshipWithShares, ShareDetails } from './types'
import { Relationship as pbRelationship } from 'proto-js'

/* Comparison function:
   B has a higher value than A iff B is set and A isn't, or both are set and B > A.
   Returns null if both values are null. */
const valBHigherThanA = (valA?: number, valB?: number): number | null => {
  if (valA) {
    if (!valB) {
      return -1
    }

    return valB - valA
  } else if (valB) {
    return 1
  }

  return null
}

type ShareInformation = {
  numShares?: number
  percentage?: number
}

/* Sort share information by percentage when available, falling back to number of shares.
   Higher values first. */
export const sortByPercentageNumber = (
  shareInfoA: ShareInformation,
  shareInfoB: ShareInformation
): number => {
  const { percentage: percentageA, numShares: numberOfSharesA } = shareInfoA
  const { percentage: percentageB, numShares: numberOfSharesB } = shareInfoB

  const bHasHigherPercentage = valBHigherThanA(percentageA, percentageB)

  if (bHasHigherPercentage !== null) {
    return bHasHigherPercentage
  }

  const bHasHigherShares = valBHigherThanA(numberOfSharesA, numberOfSharesB)

  if (bHasHigherShares !== null) {
    return bHasHigherShares
  }

  return 0
}

/* Sort by elevated status before sorting by percentage and number of shares */
export const sortByStatusPercentageNumber = (
  relationshipA: RelationshipWithShares,
  relationshipB: RelationshipWithShares
): number => {
  const {
    elevatedStatus: statusA,
    maxPercentage: percentageA,
    maxNumShares: numberOfSharesA
  } = relationshipA
  const {
    elevatedStatus: statusB,
    maxPercentage: percentageB,
    maxNumShares: numberOfSharesB
  } = relationshipB

  if (statusA === statusB) {
    return sortByPercentageNumber(
      {
        percentage: percentageA,
        numShares: numberOfSharesA
      },
      {
        percentage: percentageB,
        numShares: numberOfSharesB
      }
    )
  }

  return statusA ? -1 : 1
}

export const convertRelationshipsToJson = (
  relationships?: typeof pbRelationship[]
): Relationship[] => {
  if (!relationships) {
    return []
  }

  return relationships.map((relationship) => {
    const { sharesList, ...otherFields } = relationship.toObject()

    return {
      ...otherFields,
      shares: sharesList
    }
  })
}

type CategorizedShareInformation = {
  upstream: RelationshipWithShares[]
  downstream: RelationshipWithShares[]
  uncategorized: Relationship[]
}

/* Categorize Share Information into three buckets:
  Those with upstream ownership information (upstream)
  Those with downstream ownership information (downstream)
  Those without ownership information (uncategorized).

  Returns information sorted by highest ownership, including the nested
  shares array. */
export const buildShareTableInformation = (
  relationships: Relationship[]
): CategorizedShareInformation => {
  const upstreamDetails: RelationshipWithShares[] = []
  const downstreamDetails: RelationshipWithShares[] = []
  const uncategorized: Relationship[] = []

  relationships.forEach(({ name, elevatedStatus, relation, shares }) => {
    shares = shares || []
    const upstreamShares: ShareDetails[] = []
    const downstreamShares: ShareDetails[] = []

    shares.forEach((share) => {
      if (share.relation.endsWith('Of')) {
        downstreamShares.push(share)
      } else {
        upstreamShares.push(share)
      }
    })

    if (upstreamShares.length) {
      upstreamShares.sort(sortByPercentageNumber)
      upstreamDetails.push({
        elevatedStatus,
        name,
        relation,
        maxPercentage: upstreamShares[0].percentage,
        maxNumShares: upstreamShares[0].numShares,
        shares: upstreamShares
      })
    }

    if (downstreamShares.length) {
      downstreamShares.sort(sortByPercentageNumber)
      downstreamDetails.push({
        elevatedStatus,
        name,
        relation,
        maxPercentage: downstreamShares[0].percentage,
        maxNumShares: downstreamShares[0].numShares,
        shares: downstreamShares
      })
    }

    if (!(upstreamShares.length || downstreamShares.length)) {
      uncategorized.push({ name, elevatedStatus, relation })
    }
  })

  return {
    upstream: upstreamDetails.sort(sortByStatusPercentageNumber),
    downstream: downstreamDetails.sort(sortByStatusPercentageNumber),
    uncategorized: uncategorized
  }
}
