import React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Divider from '@mui/material/Divider'
import TableRow from '@mui/material/TableRow'

import { getCountryName } from 'lib/entity'
import Highlight from 'components/lib/Highlight'
import { Can } from 'lib/authorization'
import UpgradeLink from 'components/lib/Auth/UpgradeLink'
import { ListEntry, Location } from 'proto-js'
import BlurredField from 'components/lib/BlurredField'
import { SectionTitle, PaneContent } from './Common'
import Typography from '@mui/material/Typography'

const StyledTableCell = styled(TableCell)(() => ({
  borderBottom: '0px solid'
}))

interface LocationRowProps {
  canViewValue: boolean
  field: string
  value: string
}

const MOCK_ADDRESS = '12345 XXXXXXXXX XXX'

const LocationRow = ({
  canViewValue = true,
  field,
  value
}: LocationRowProps) => {
  if (value === '') return null

  return (
    <TableRow>
      <StyledTableCell variant='head'>{field}</StyledTableCell>
      <StyledTableCell variant='body'>
        {canViewValue ? (
          <Highlight str={value} />
        ) : (
          <BlurredField data-cy='blurred-item'>{MOCK_ADDRESS}</BlurredField>
        )}
      </StyledTableCell>
    </TableRow>
  )
}

interface LocationTableProps {
  canViewValues: boolean
  location: typeof Location
  divider: boolean
}

const LocationTable = ({
  canViewValues,
  location,
  divider
}: LocationTableProps) => {
  const address = location.getAddress()
  const city = location.getCity()
  const state = location.getState()
  const postal = location.getPostal()
  const region = location.getRegion()
  const country = location.getCountry()

  return (
    <>
      <Table size='small'>
        <colgroup>
          <col width='20%' />
          <col width='80%' />
        </colgroup>
        <TableBody>
          <LocationRow
            field='Address'
            canViewValue={canViewValues}
            value={address}
          />
          <LocationRow field='City' canViewValue={canViewValues} value={city} />
          <LocationRow
            field='State'
            canViewValue={canViewValues}
            value={state}
          />
          <LocationRow
            field='Postal'
            canViewValue={canViewValues}
            value={postal}
          />
          <LocationRow
            field='Region'
            canViewValue={canViewValues}
            value={region}
          />
          {country !== undefined && (
            <LocationRow
              field='Country'
              canViewValue
              value={getCountryName(country)}
            />
          )}
        </TableBody>
      </Table>
      {divider && <Divider variant='fullWidth' />}
    </>
  )
}

interface LocationPaneProps {
  entity: typeof ListEntry
}

const LocationsPane = ({ entity }: LocationPaneProps): JSX.Element => {
  const addresses = entity.getAddressesList()
  const pobs = entity.getPobsList()

  return (
    <PaneContent>
      <Can I='view' a='Screen' field='location' passThrough>
        {(allowed: boolean) => (
          <>
            {addresses.length > 0 && <SectionTitle title='Addresses' />}
            {addresses.map((l: typeof Location, i: number) => (
              <LocationTable
                canViewValues={allowed}
                key={i}
                location={l}
                divider={i !== addresses.length - 1} // Don't show divider on last element
              />
            ))}

            {pobs.length > 0 && <SectionTitle title='Place of Birth' />}
            {pobs.map((l: typeof Location, i: number) => (
              <LocationTable
                canViewValues={allowed}
                key={i}
                location={l}
                divider={i !== pobs.length - 1} // Don't show divider on last element
              />
            ))}
            {!allowed && (addresses.length > 0 || pobs.length > 0) && (
              <Typography align='center' variant='subtitle1'>
                <UpgradeLink additionalText='to see additional location information.' />
              </Typography>
            )}
          </>
        )}
      </Can>
    </PaneContent>
  )
}

export default LocationsPane
