import React from 'react'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import Launch from '@mui/icons-material/Launch'

const Title = styled(Typography)({
  fontWeight: 500
})

interface SectionTitleProps {
  title: string
}

export const SectionTitle = ({
  title,
  ...otherProps
}: SectionTitleProps): JSX.Element => {
  return <Title {...otherProps}>{title.toUpperCase()}</Title>
}

export const PaneContent = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(2)
}))

interface RelationshipLinkProps {
  name: string
  listSourceQueryString: string
}

export const RelationshipLink = ({
  name,
  listSourceQueryString
}: RelationshipLinkProps): JSX.Element => (
  <Link
    component={RouterLink}
    target='_blank'
    to={`/search?name=${encodeURIComponent(
      name
    )}${listSourceQueryString}&expand=true`}
    underline='hover'
  >
    <>
      {name}
      <sup>
        <Launch titleAccess='search in new tab' fontSize='inherit' />
      </sup>
    </>
  </Link>
)
