import React from 'react'
import { styled } from '@mui/material/styles'

import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import WarningIcon from '@mui/icons-material/Warning'
import { SvgIconProps } from '@mui/material/SvgIcon'
import { RelationshipWithShares, ShareDetails } from './../types'
import { SectionTitle, RelationshipLink } from './../../Common'

interface ShareRowProps {
  index: number
  name: string
  elevatedStatus: boolean
  details: ShareDetails
  listSourceQueryString: string
}

interface SharesInformationProps {
  downstream?: RelationshipWithShares[]
  upstream?: RelationshipWithShares[]
  listSourceQueryString: string
}

interface SharesTableProps {
  title: string
  relationships: RelationshipWithShares[]
  listSourceQueryString: string
}

const ShareHeaderRow = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  fontWeight: 600
}))

const CellWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center'
})

const NameCell = styled(TableCell)({
  width: '25%'
})

const TypeCell = styled(TableCell)({
  width: '180px'
})

const ValueCell = styled(TableCell)({
  width: '150px'
})

const DateCell = styled(TableCell)({
  width: '125px'
})

const ShareTableBlock = styled('div')({
  paddingBottom: '16px'
})

interface RiskIconProps extends SvgIconProps {
  color?: 'error' | 'warning'
}

const RiskIcon = styled((props) => (
  <WarningIcon fontSize='small' color='error' {...props} />
))<RiskIconProps>({
  marginLeft: '6px'
})

const StyledTable = styled(Table)({
  marginTop: '16px'
})

type SharesCellProps = {
  numShares?: number
  percentage?: number
}

export const SharesCell = ({
  numShares,
  percentage
}: SharesCellProps): JSX.Element => {
  let displayString = ''

  if (percentage) {
    const formattedPercentage = Math.round(percentage)

    if (formattedPercentage < 1) {
      displayString += '< 1%'
    } else {
      displayString += `${formattedPercentage}%`
    }
  }

  if (numShares) {
    const formattedShares = numShares.toLocaleString()

    if (percentage) {
      displayString += ` (${formattedShares})`
    } else {
      displayString += formattedShares
    }
  }

  return <TableCell>{displayString}</TableCell>
}

export const ShareRow = ({
  index,
  listSourceQueryString,
  name,
  elevatedStatus,
  details: {
    fromDate,
    toDate,
    currency,
    percentage,
    numShares,
    monetaryValue,
    relation
  }
}: ShareRowProps): JSX.Element => {
  return (
    <TableRow>
      <NameCell>
        {index === 0 && (
          <CellWrapper data-cy='relationship-name'>
            <RelationshipLink
              name={name}
              listSourceQueryString={listSourceQueryString}
            />{' '}
            {elevatedStatus && <RiskIcon titleAccess='Elevated Risk Entry' />}
          </CellWrapper>
        )}
      </NameCell>
      <TypeCell>{relation}</TypeCell>
      <SharesCell percentage={percentage} numShares={numShares} />
      <ValueCell>
        {monetaryValue !== 0 && monetaryValue?.toLocaleString()} {currency}
      </ValueCell>
      <DateCell>{fromDate}</DateCell>
      <DateCell>{toDate}</DateCell>
    </TableRow>
  )
}

const SharesTable = ({
  listSourceQueryString,
  title,
  relationships
}: SharesTableProps): JSX.Element => {
  const rows = relationships.map(
    (relationship: RelationshipWithShares, index) => {
      return relationship.shares.map(
        (shareInformation: ShareDetails, shareIndex) => {
          return (
            <ShareRow
              key={`${index}_${shareIndex}`}
              index={shareIndex}
              elevatedStatus={relationship.elevatedStatus}
              name={relationship.name}
              details={shareInformation}
              listSourceQueryString={listSourceQueryString}
            />
          )
        }
      )
    }
  )

  return (
    <ShareTableBlock>
      <SectionTitle data-cy='table-title' title={title} />
      <StyledTable size='small'>
        <ShareHeaderRow>
          <TableRow>
            <NameCell>Name</NameCell>
            <TypeCell>Type</TypeCell>
            <TableCell>Shares</TableCell>
            <ValueCell>Value</ValueCell>
            <DateCell>Start Date</DateCell>
            <DateCell>End Date</DateCell>
          </TableRow>
        </ShareHeaderRow>
        <TableBody>{rows}</TableBody>
      </StyledTable>
    </ShareTableBlock>
  )
}

const SharesInformation = ({
  downstream,
  upstream,
  listSourceQueryString
}: SharesInformationProps): JSX.Element => {
  return (
    <>
      {upstream && upstream.length !== 0 && (
        <SharesTable
          title='Upstream Ownership'
          listSourceQueryString={listSourceQueryString}
          relationships={upstream}
        />
      )}
      {downstream && downstream.length !== 0 && (
        <SharesTable
          title='Downstream Ownership'
          listSourceQueryString={listSourceQueryString}
          relationships={downstream}
        />
      )}
    </>
  )
}

export default SharesInformation
